<template>
  <a-table
    :columns="columns"
    :data-source="archivedCycles"
    :loading="isFetchingModelTypes"
  >
    <template #bodyCell="{ record, column }">
      <template v-if="column.key === 'action'">
        <a-button type="primary" @click="RestoreArchivedCycle(record.id)">
          Restore
        </a-button>
      </template>
    </template>
  </a-table>
</template>
<script>
import { FormOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import { columns } from './config';

export default {
  components: {
    FormOutlined,
  },

  setup() {
    return {
      columns,
    };
  },

  computed: {
    ...mapGetters(['modelTypes', 'isFetchingModelTypes', 'archivedCycles']),
  },

  async created() {
    await this.fetchArchivedCycles();
  },

  methods: {
    ...mapActions(['fetchArchivedCycles', 'restoreArchivedCycles']),

    async RestoreArchivedCycle(id) {
      await this.restoreArchivedCycles(id);
    },

    getFilePath(file) {
      const { file_name, file_path } = file;
      if (!file_path) return file_name;
      return `${file_path}/${file_name}`;
    },
  },
};
</script>
<style></style>
